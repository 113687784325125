@import "../../global.scss";

.home {
    
    .imgContainer {
        height: 100vh;
        width: 100vw;
        display: flex;
        position: relative;
        background-image: url("../../assets/introbackground.png");
        background-repeat: no-repeat;
        background-size: cover;

        @include mobile{
            background-position: 25% 50%;
        }
    }

    .imgContainer::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.85);
    }
}

.hello {
    top: 50%;
    left: 50%;
    font-size: 60px;
    color: white;
    transform: translate(-50%, -50%);
    position: absolute;

    @include mobile{
        font-size: 30px;
    }
    
}

.menu {
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    
    h4 {
        color: lightgray;
        padding-right: 25px;
        font-weight: lighter;
      }

    h4:hover {
        color: gray;
        text-decoration: underline;
      }

    a {
        text-decoration: none;
        color: lightgray;
        padding-left: 25px;
    }

    a:hover {
        color: gray;
        text-decoration: underline;
    }

}


.ityped-cursor {
    animation: blink 1s infinite;
    font-size: 5rem;

    @include mobile{
        font-size: 2.5rem;
    }
    
}


@keyframes blink {
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


